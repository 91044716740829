<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" :title="title" width="30%" @close="close">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        label-suffix="："
      >
      <el-form-item label="客户来源" prop="customerSourceName">
        <el-input placeholder="请输入" v-model="ruleForm.customerSourceName"></el-input>
      </el-form-item>
    </el-form>
      <template #footer>
        <el-button @click="close">取消</el-button>
        <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { addCustomerSource,editCustomerSource } from '@/service/main/customer'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
  },
  components: {
  },
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      customerSourceName:null
    })
    const rules = {
      customerSourceName: [{ required: true, message: '请输入', trigger: 'blur' }],
    }
    const editId = ref(null)
    const ruleFormRef = ref()
    const isShow = ref(false)
    const title = ref('添加客户来源')
    const open = (data) =>{
      if (data) {
        title.value = '编辑客户来源'
        editId.value = data.customerSourceId
        ruleForm.customerSourceName = data.customerSourceName
      }else{
        title.value = '添加客户来源'
      }
      isShow.value = true
    }
    const close = () =>{
      isShow.value = false
      ruleFormRef.value.resetFields()
      ruleForm.customerSourceName=''
    }

    const handleSaveClick = async(formEl) => {
      if (!formEl) return
        await formEl.validate(async(valid, fields) => {
          if (valid) {
            let res;
            if (editId.value) {
              res = await editCustomerSource({...ruleForm,id:editId.value})
            }else{
              res = await addCustomerSource({...ruleForm})
            }
            if (res.code == 0) {
              ElMessage({
                message: '保存成功!',
                type: 'success',
              })
              emit('refresh')
              close()
            }else{
              ElMessage({
                message: res.msg,
                type: 'error',
              })
            }
          }
        })
    }

    return {
      handleSaveClick,
      isShow,
      ruleForm,
      title,
      ruleFormRef,
      open,
      rules,
      close,
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
