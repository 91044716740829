<template>
    <div class="customer-list">
        <breadcrumb :breadcrumbList="breadcrumbSet"></breadcrumb>
        <div class="customer-list-content">
            <el-button type="primary" @click="openForm">添加</el-button>
            <div class="coupons-list" style="margin-top: 10px;">
                <el-table ref="dataTableRef" :data="dataList" highlight-current-row>
                    <el-table-column label="序号" type="index" align="center" />
                    <el-table-column label="客户来源" prop="customerSourceName" align="center" />
                    <el-table-column label="操作" align="center">
                        <template #default="scope">
                            <el-button @click="handleEdit(scope.row)" type="primary">编辑</el-button>
                            <el-button @click="handleRemove(scope.row.customerSourceId)" type="danger">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
    <FormInfo ref="FormInfo" @refresh="getDataList"></FormInfo>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted, nextTick, handleError } from 'vue'
import { ElMessage } from 'element-plus'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbSet } from '../list/config/index'
import FormInfo from './cpns/form.vue'
import { customerSourceList,deleteCustomerSource } from '@/service/main/customer/index.js'
import { popup } from '@/utils/pop-up'
export default defineComponent({
    props: {
    },
    components: {
        Breadcrumb,
        FormInfo
    },
    setup(props, { emit }) {
        const dataList = ref([])
        const FormInfo = ref()
        const handleRemove = async(id) => {
            popup('确认删除该来源吗?', async() => {
                let res = await deleteCustomerSource({id})
                if (res.code==0) {
                    ElMessage.success({
                        message: '删除成功!',
                        type: 'success'
                    })
                    getDataList()
                }
            })
        }
        const handleEdit = (item) =>{
            FormInfo.value.open(item)
        }

        const openForm = () =>{
            FormInfo.value.open()
        }

        const getDataList = async () => {
            let res = await customerSourceList()
            dataList.value = res.data
        }
        getDataList()

        return {
            dataList,
            handleRemove,
            breadcrumbSet,
            FormInfo,
            getDataList,
            openForm,
            handleEdit
        }

    }
})
</script>

<style scoped lang="less">
.customer-list {
    min-width: 980px;

    .customer-list-content {
        margin: 20px;
        background-color: #fff;
        padding: 20px;
        min-height: 90vh;
    }
}
</style>